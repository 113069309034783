<template>
  <div class="col-md-12 col-lg-10 center">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <CAlert :color="alertColor" closeButton :show.sync="alertShow">
              {{ alertMessage }}
            </CAlert>
          </CCol>
        </CRow>
        <CRow class="align-items-center">
          <CCol> Create new session </CCol>
          <CCol md="auto">
            <div class="text-right">
              <CButton color="primary" @click="saveItem">
                Create Session
              </CButton>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CInput label="Name" :value.sync="sessionName" placeholder="Session name" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import { AUTH_LOGOUT } from "../store/actions/auth";

export default {
  name: "NewItem",
  data() {
    return {
      alertColor: "success",
      alertShow: false,
      alertMessage: "",
      sessionName: "",
    };
  },
  methods: {
    saveItem() {
      var sessionData = {
        user_id: this.$store.state.user.profile.data.user_id,
        conversation_name: this.sessionName,
      };

      axios
        .post(config.VUE_APP_ENV_ASSISTANT_URL + "/sessions", sessionData)
        .then((resp) => {
          this.showAlert("success", "New session is created");
          this.$router.push("/sessions/" + resp.data.session.conversation_id);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
              this.$router.push("/login");
            });
          } else {
            this.showAlert("warning", "Failed to create session: " + err);
          }
        });
    },
    showAlert(color, messsage) {
      this.alertShow = true;
      this.alertColor = color;
      this.alertMessage = messsage;
    },
    notEmpty(val) {
      return val && val.length >= 3;
    },
  },
};
</script>

<style scoped>
.center {
  margin: 0 auto;
}

/* Define color for the selected option */
.select-selected {
  color: black !important;
}

/* Define color for the placeholder text */
.select-placeholder {
  color: gray !important;
}
</style>
